* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    scrollbar-width: thin;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 8px;
    scrollbar-width: thin;
    // max-width: 0px;
}
*::-webkit-scrollbar-track {
    background-color: #1b1b1b;
}
*::-webkit-scrollbar-thumb {
    background-color: rgba(253, 253, 253, 0.842);
    border-radius: 25px;
    border: transparent;
}
